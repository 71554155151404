import React from "react"
import Layout from "../components/Layout"
import PageHeader from '../components/PageHeader'
import ContainerWrapper from '../components/Container'
import { Helmet } from "react-helmet"

const WhoWeArea = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Who We Are - IMSSDARM Asian Division</title>
        <link rel="canonical" href="https://imssdarm.asia/who-we-are" />
        <meta name="description" content="The International Missionary Society is a Christian denomination that takes the Bible as its sole foundation of faith, practice, and believes in Jesus as the Son of God and the Redeemer. His imminent second coming to earth, faithfulness to the Ten Commandments of God, and the principle of nonviolence are central to the movement’s fundamental beliefs. Having its roots in the advent message preached in the nineteenth century."></meta>
      </Helmet>
      <div className="churches">
        <PageHeader>
          <h1>Who We Are</h1>
        </PageHeader>

        <ContainerWrapper>
          <div className="content">
            <p>The International Missionary Society is a Christian denomination that takes the Bible as its sole foundation of faith, practice, and believes in Jesus as the Son of God and the Redeemer. His imminent second coming to earth, faithfulness to the Ten Commandments of God, and the principle of nonviolence are central to the movement’s fundamental beliefs. Having its roots in the advent message preached in the nineteenth century, the organization considers the preaching of the gospel to all the world to be its primary mission.</p>

            <p>The spiritual foundation of the International Missionary Society was laid at the beginning of the Advent Movement. Having first adopted the name Seventh-day Adventists in 1861, when the first General Conference was organized in Battle Creek, Michigan, the constituting churches were invited to adopt the covenant “to keep the commandments of God and the faith of Jesus.”</p>

            <p>Faithfulness to the Ten Commandments included the position of pacifism, based on the belief that it is not possible to accept the holy principles of the moral law and at the same time take the life of one’s neighbor through participation in war. Therefore, during the American Civil War (1861-1865), the denomination was unanimous in “their view that its [the Ten Commandments’] teachings are contrary to the spirit and practice of war; hence, they have ever been conscientiously opposed to bearing arms.” The denomination was registered with the Michigan state government. In 1865, the General Conference in session repeated the principle by resolving: “… We are compelled to decline all participation in acts of war and bloodshed as being inconsistent with the duties enjoined upon us by our divine Master toward our enemies and toward all mankind.”</p>

            <p>At a General Conference in 1888, the life-changing message of “Christ Our Righteousness” was presented. Leaders and delegates understood the message in different ways, with the result that this message caused division rather than unity. Events in the following decades revealed that the church was not preparing to face increasingly difficult tests.</p>

            <p>The pacifist position as a moral issue continued to be defended, but here and there individuals began to change their minds. At the end of the 19th century, leaders and workers were divided over this principle. Alarming news revealed that some considered it admissible and justifiable to become involved in politics and even warfare to support earthly governments. A message given during the 1897 General Conference session attested to the fact that such a process was ongoing and</p> strengthening.

            <p>As the advent message spread outside of North America, in various countries the rejection of bearing arms met conflicting circumstances and laws. Especially in authoritarian and militarized nations there was no willingness to grant exemption from military service, and even in peacetime more and more problems arose over this issue, along with confrontation with authorities and governments. This being the case in time of peace, what would it be like in time of war? That question was answered when World War I erupted.</p>

            <p>The General Conference Committee of Seventh-day Adventists had to face the emergency and made a choice. Instead of continuing to uphold the previous principle of pacifism, the leadership opted to follow the laws of men. While the members were told to follow their own personal convictions in the matter, the leaders made it clear that not obeying the government would have very negative consequences. Now, instead of adhering to the gospel teaching, variable personal conviction became the norm.</p>

            <p>In some countries involved in war, Seventh-day Adventist leaders went even further. To spare the denomination the ire of the government, to avoid the loss of church property and the closing of churches, and to keep members from being arrested and executed for refusing to bear arms, church leaders gave in to government pressure. Wishing to stave off conflicts with civilian and military authorities or the appearance of opposing state law in time of national crisis, church leaders in various European nations, and other countries as well, found no other solution than to consent to serve in the military, bear weapons, and participate in warfare also on the holy Sabbath day. Some went so far as to declare that all of this harmonized with the Bible’s teaching. Thousands of Seventh-day Adventist members were called to join the army and participated in bloodshed and warfare.</p>

            <p>This caused a great crisis in the church. The great majority accepted the new trend, but a minority here and there objected and resisted the organization’s resolutions. Being convinced that national laws cannot override God’s law, these minority laymen and church elders defended the pacifist position, regardless of the consequences. Some were imprisoned and suffered harsh punishment, and others met death in remaining faithful to principle</p>

            <p>Those who cooperated with the worldly governments feared that the government might confuse them with the ones who resisted warfare and subject them to the same treatment, so the minority was removed from church fellowship. Almost four thousand members in the different countries of Europe made this experience. Those who upheld and defended the church’s original pacifist position had just one great interest and heartfelt desire–to remain faithful in defense of truth and to preach the message of hope and salvation to the world. They were a remnant scattered throughout various countries and generally without any knowledge of others like themselves. In the early years they were called by different names, including “International Missionary Society,” “Reform Movement,” “Reformers,” “Reform Church,” “Adventist Reformers,” or, formally, “International Missionary Society, Seventh-day Adventist Reform Movement.”</p>

            <p>To face the emergency and encourage one other, whenever possible, those who had been removed from the Seventh-day Adventist Church held meetings and organized informally. Those who had been leaders were persecuted and forced to go underground. When hostilities ended and there was again freedom to meet and preach, conferences were held in Erfurt and Magdeburg, Germany. Hoping that those who had left the original teachings of Adventism would return to the original platform of truth, the faithful Adventists preached the message as before. In 1919, they registered the German Union so that printing, distribution of literature, and public meetings could be conducted and property could be held in the organization’s name.</p>

            <p>Requests for investigation of and a solution to the problems that had arisen during the war were presented to the Adventist leadership by the reform representatives. At a meeting in Friedensau, Germany, in 1920, it become clear that the leaders of the General Conference were more interested in continuing to grant free choice to every country and member in the world to decide how they would relate to military service in their own country rather than to go back to their original position, which had been defended by the minority and was now classified as a private opinion.</p>

            <p>IMS Headquarters, Cedartown, GA, USA</p>

            <p>In 1922, a second attempt was made to bring the issues before the delegates meeting at a General Conference in San Francisco, California, U.S. But there was fear on the part of the Adventist leadership that bringing such issues to the delegates would cause irreparable damage to the Adventist organization. Therefore, the representatives of the International Missionary Society were not allowed to present anything, and their petition for a hearing was rejected. There was no going back. The Seventh-day Adventist Church continued on its way, and the International Missionary Society turned its attention to carrying out what it saw as its mission.</p>

            <p>In the ensuing years, the International Missionary Society faced additional tests during the years of Nazi domination in Germany and World War II, as well as in other nations with dictatorial governments, even in Africa. There were times when it had to pay a price in blood as members sealed their faith and testimony with martyrdom. Nevertheless, often just one soul at a time, the gospel message has been carried from continent to continent and now is preached in more than 120</p> countries. The burning desire of every faithful member in the International Missionary Society is for the gospel to triumph and reach every corner of the world where children of God may be raised up. The promise of Jesus is: “Blessed are the peacemakers: for they shall be called the children of God.” Matthew 5:9.

            <p>Fleschutz, Hans, And Follow Their Faith, Religious Liberty Publishing Assn., Huntington Park, CA, 1998.</p>

            <p>History of the S.D.A. Reform Movement, International Missionary Society, Washington, DC, ca. 1925. <a href="http://sda1888.org/page.cl/HistoryoftheSDARefor">http://sda1888.org/page.cl/HistoryoftheSDARefor</a></p>

            <p>Reformation, Religious Liberty Publishing Assn., Denver, CO, 1989.</p>

            <p>Reformation Study Course, International Missionary Society of Seventh-day Adventists, Reform Movement, American Union, 1972. <a href="http://sda1888.org/page.cl/ReformationStudyCour">http://sda1888.org/page.cl/ReformationStudyCour</a></p>

            <p>Seventh-day Adventist Encyclopedia, Review and Herald Publishing Association, Washington, DC, 1976, pp. 1332, 1333.</p>

            <p>Standish, Russell R. and Colin D., Half a Century of Apostasy, Highwood Books, Narbethong, Victoria, Australia, 2006.</p>

            <p>Wilcox, Francis McLellan, Seventh-day Adventists in Time of War, Review and Herald Publishing Assn., Washington, DC. 1936.</p>

          </div>
        </ContainerWrapper>

      </div>
    </Layout>
  )
}

export default WhoWeArea;
